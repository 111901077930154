import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';

// slider images
import slide01 from '../../../assets/images/slider/img1.jpg'
import slide02 from '../../../assets/images/slider/img2.jpg'
import slide03 from '../../../assets/images/slider/img4.jpg'

const slide = props => {
    return (
        <OwlCarousel 
            className='slider-carousel'
            animateIn='fadeIn'
            animateOut='fadeOut'
            autoplay='true'
            loop='true'
            items='1'
            dots={false}
        >
            <div className="slide slide-3" style={{ backgroundImage: 'url(' + slide03 + ')' }}></div>
            <div className="slide slide-2" style={{ backgroundImage: 'url(' + slide02 + ')' }}></div>
            <div className="slide slide-1" style={{ backgroundImage: 'url(' + slide01 + ')' }}></div>
        </OwlCarousel>
    )
}

export default slide