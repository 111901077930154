import { Form, Input, Button, Checkbox, Col, Row, InputNumber, Select } from 'antd';
import React, { Component, useState  } from 'react';
import { DatePicker } from 'antd';
import { performRequest, API_BASE_URL } from "../../Services/api-handler";
import { Spin, Alert, Upload } from 'antd';
import { success, error} from "../../Components/message";
import { Switch } from 'antd';
import moment from 'moment';
import { UploadOutlined } from '@ant-design/icons';
const { Option } = Select;
const { MonthPicker, RangePicker } = DatePicker;

export const GoodStandingForm = (props) => {
  const [showLoader, setShowLoader] = useState(false);
  const onFinish = values => {
    // console.log(values,'valueseeeeeeeeee');
    setShowLoader(true);
    values.applications = parseInt(props.match.params.id);
    console.log(values,'valueseeeeeeeeee');
    if(values.hasOwnProperty('file') == true && 
    values.file.hasOwnProperty('file') == true &&
    values.file.file.hasOwnProperty('status') === true &&
    values.file.file.status === 'done'){
      const result = {
        name: values.name,
        body_text: values.body_text,
        applications: values.applications,
        number: values.number,
        email: values.email,
        council_from: values.council[0].format('YYYY-MM-DD'),
        council_to: values.council[1].format('YYYY-MM-DD'),
        country_from: values.country[0].format('YYYY-MM-DD'),
        country_to: values.country[1].format('YYYY-MM-DD'),
        license_expired: values.isValid,
        file_path: values.file.file.response.file_name,
        type: values.file.file.type,
        file_name: values.file.file.name
      }
      console.log(result,'valueseeeeeeeeee');
      performRequest('post', "good_standing_certificate/", result, true).
        then(res=>{
          setShowLoader(false);
          success('Your query send successfully.You can expect a mail by soon');
        }).
        catch(err=>{
            if(err.response.status === 400){
              error('You have already done enquiry on this applications');
            }else{
              error('Sorry for inconvenience caused.');
            }
            setShowLoader(false);
        })

    }
     
  };
  const onFinishFailed = errorInfo => {
  };
  const [form] = Form.useForm();
  const validateMessages = {
    required: '${label} is required!'
  };
  const initialValues = {};
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 10 },
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 10 },
  };
  const validateEmail = (rule: any, value: any, callback: any) =>{
    if(value){
      value = value.replace(/\s/g, "");
      var phoneno = /^\+?([0-9]{3})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{5})$/;
      if(value.match(phoneno)){
        callback();
      }else{
        callback("Phone number is not valid");
      }
    }else{
      callback();
    }
  }
  const mobileWrapper = {
    wrapperCol: { span: 4 },
  }
  const { TextArea } = Input;
  const rangeConfig = {
    rules: [{ type: 'array', required: true, message: 'Please select Date!' }],
  };
  const uploadFile = (files) =>{
    console.log(files)
  }
  return (
    <React.Fragment>
    {showLoader?<Spin tip="Sending...">
        <Alert
        message="Thanks for contacting us."
        description="We will contact you as soon as possible."
        type="info"
        />
    </Spin>:""}
    <Form 
      onFinish={onFinish}
      form={form} name="packages"
      layout={"vertical"}
      initialValues={{isValid: true}}
      validateMessages={validateMessages}
      className="sb-form"
    >
      <div className="container">
        <div className="form-notice">
          <h6>Please provide following information so that we can verify the details and get back to you as soon as possible.</h6>
        </div>
      </div>
      <div className="row">
          <div className="col-md-4">
           <Form.Item
              label="Name Of The candidate"
              name="name"
              rules={[{ required: true, message: 'Please enter your name!' }]}
            >
              <Input />
            </Form.Item>
          </div>
          <div className="col-md-4">
           <Form.Item
              label="Email"
              name="email"
              rules={[{
                        type: 'email', message: 'The input is not valid email',
                      }, {
                        required: true, message: 'Please input your email',
                      }]}
            >
              <Input />
            </Form.Item>
          </div>
          <div className="col-md-4">
           <Form.Item
              label="Whatsapp Number"
              name="number"
              rules={[{ required: true, message: 'Please enter your Whatsapp Number!' },
                      { validator: validateEmail}
                      ]}
            >
              <Input placeholder="enter country code also example(+971 50 229 7711)"/>
            </Form.Item>
          </div>
          <div className="col-md-4">
            <Form.Item name="council" label="Certificate required Council(From/To) " {...rangeConfig}>
              <RangePicker />
            </Form.Item>
          </div>
          <div className="col-md-4">
            <Form.Item name="country" label="Certificate required Country(From/To) " {...rangeConfig}>
              <RangePicker />
            </Form.Item>
          </div>
          <div className="col-md-4">
            <Form.Item name="isValid" label="Registration/ License is not expired">
              <Switch  defaultChecked={true}/>
            </Form.Item>
          </div>
          <div className="col-md-4">
            <Form.Item
              label="Attach The Registration/License certificate copy"
              name="file"
              rules={[{ required: true, message: 'Please upload file' }]}
            >
            <Upload onChange={uploadFile} action={API_BASE_URL+"upload_files/"} >
              <Button>
                <UploadOutlined /> Upload File
              </Button>
            </Upload>
            </Form.Item>
          </div>
          <div className="col-md-4">
            <Form.Item
              label="Message"
              name="body_text"
              rules={[{ required: true, message: 'Please enter your querys!' }]}
            >
              <TextArea rows={4}/>
            </Form.Item>
          </div>
        <div className="col-md-12">
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">Save</Button>
          </Form.Item>
        </div>
      </div>
    </Form>
    </React.Fragment>
  );
};