import React, { Component } from "react";
import PageLayout from "../Page";
import ContactForm from "../../Components/UI/Forms/Contact";

class Contact extends Component {
    render() {
        return (
            <section className="contact">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-9 m-auto text-center">
                            <div className="sec-heading">
                                <span className="tagline">Contact us</span>
                                <h3 className="sec-title">
                                    If You Have Any Query, <br />
                                    Drop a Message
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            <ContactForm {...this.props} />
                        </div>
                        <div className="col-md-4">
                            <aside className="sidebar">
                                <div className="widget contact-info">
                                    <h3 className="widget-title">
                                        Contact info
                                    </h3>
                                    <ul>
                                        <li>RAG GLOBAL BUSINESS HUB</li>
                                        <li>GROUND FLOOR</li>
                                        <li>AL HILAL BANK BUILDING</li>
                                        <li>AL QUASIS 2</li>
                                        <li>DUBAI</li>
                                    </ul>
                                    <div>
                                        <h6>WhatsApp Number</h6>
                                        <a href="tel:00971502297711">
                                            00971 502 297 711
                                        </a>
                                        <a href="tel:00919778488246">
                                            00919 778 488 246
                                        </a>

                                    </div>
                                    <div>
                                        <h6>Email Us</h6>
                                        <a href="mailto:contact@forinplus.com">
                                            contact@forinplus.com
                                        </a>
                                        <a href="mailto:pro@forinplus.com">
                                            pro@forinplus.com
                                        </a>

                                    </div>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Contact;
