import React from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/images/logo1.png";
import $ from "jquery";

const navbar = () => {
    const onClickChanges = () =>{
        if($('.has-menu-child').find('.menu-dropdown').length == 0){
            
            $('.has-menu-child').append('<i class="menu-dropdown ti-angle-down"></i>');
            if ($(window).width() <= 991) {
                $('.menu-dropdown').on('click', function() {
                    $(this).prev().slideToggle('slow');
                    $(this).toggleClass('ti-angle-down ti-angle-up')
                })
            }
        }else{
            $('nav.navbar > ul').slideToggle();
        }
        
    }
    return (
        <header className="header">
            <div className="container">
                <nav className="navbar">
                    <Link to="/" className="logo">
                        <img src={Logo} alt="" />
                    </Link>
                        <i className="ti-align-justify" id="mobile-menu-toggler" onClick={onClickChanges}></i>
                    <ul className="navbar-nav">
                        <li>
                            <Link to="">Home</Link>
                        </li>
                        <li className="has-menu-child">
                            <Link to="/">Attestation</Link>
                            <ul className="sub-menu application-width">
                                <li className="attestion-li">
                                    <Link className="applications" to="/attestation/1">MEA Attestation</Link>
                                    <Link className="applications" to="/attestation/2">HRD Attestation</Link>
                                    <Link className="applications" to="/attestation/3">MOFA Attestation</Link>
                                    <Link className="applications" to="/attestation/4">Embassy Attestation</Link>
                                    <Link className="applications" to="/attestation/5">UAE Attestation</Link>
                                </li>
                                
                            </ul>
                        </li>
                        <li className="has-menu-child">
                            <Link to="/">Business Setup</Link>
                            <ul className="sub-menu application-width">
                                <li className="application-li">
                                    <Link className="applications" to="/bussiness_setup/111">Labour & Imigration works</Link>
                                    {/* <Link className="applications" to="/bussiness_setup/121">Medical service</Link> */}
                                    <Link className="applications" to="/bussiness_setup/131">Tas heel
</Link>
                                    <Link className="applications" to="/bussiness_setup/141">P.R.O Services</Link>
                                    <Link className="applications" to="/bussiness_setup/151">Documents clearing</Link>
                                    <Link className="applications" to="/bussiness_setup/161">Tourist & Visa for UAE</Link>
                                    <Link className="applications" to="/bussiness_setup/171">Visa for USA and Europe</Link>
                                    <Link className="applications" to="/bussiness_setup/181">Translation Services
</Link>
                                    <Link className="applications" to="/bussiness_setup/191">Government Approvals</Link>
                                    <Link className="applications" to="/bussiness_setup/101">Saudi Police Clarence Certificate
</Link>
                                    <Link className="applications" to="/bussiness_setup/112">UAE Police Clarence Certificate</Link>
                                   </li>
                                
                            </ul>
                        </li>
                        <li className="has-menu-child">
                            <Link to="/">Applications</Link>
                            <ul className="sub-menu application-width">
                                <li className="application-li">
                                    <Link className="applications" to="/applications/1">Australian Health Practitioner Regulation Agency</Link>
                                    <Link className="applications" to="/applications/2">Nursing and Midwifery Board of Ireland</Link>
                                    <Link className="applications" to="/applications/3">Nursing and Midwifery Council of United Kingdom</Link>
                                    <Link className="applications" to="/applications/4">Department of health</Link>
                                    <Link className="applications" to="/applications/5">Dubai Health Authority</Link>
                                    <Link className="applications" to="/applications/6">Ministry of Health and Prevention UAE</Link>
                                    <Link className="applications" to="/applications/7">Hamad Medical Corporation</Link>
                                    <Link className="applications" to="/applications/8">Oman Medical Speciality Board</Link>
                                    <Link className="applications" to="/applications/9">National Health Regulatory Authority</Link>
                                    <Link className="applications" to="/applications/10">Saudi Commission For Health Specialties</Link>
                                    <Link className="applications" to="/applications/11">Good Standing Certificate/Verification</Link>
                                    <Link className="applications" to="/applications/12">Registration Renewal or Transfer/License Transfer</Link>
                                    <Link className="applications" to="/applications/13">Documents Attestation</Link>
                                    <Link className="applications" to="/applications/14">Data Flow Verification</Link>
                                    <Link className="applications" to="/applications/15">NCLEX RN</Link>
                                    <Link className="applications" to="/applications/16">PLAB</Link>
                                    <Link className="applications" to="/applications/17">VETASSESS</Link>
                                    <Link className="applications" to="/applications/18">USMLE</Link>
                                    <Link className="applications" to="/applications/19">HCPC</Link>
                                    <Link className="applications" to="/applications/20">CORU</Link>
                                    <Link className="applications" to="/applications/21">SCFHS</Link>
                                    <Link className="applications" to="/applications/22">HMS</Link>
                                    <Link className="applications" to="/applications/23">CGFNS</Link>
                                    <Link className="applications" to="/applications/24">NNAS</Link>
                                    <Link className="applications" to="/applications/25">NHS</Link>
                                    <Link className="applications" to="/applications/26">ERES</Link>
                                    <Link className="applications" to="/applications/27">AES</Link>
                                    <Link className="applications" to="/applications/28">WESS</Link>
                                    <Link className="applications" to="/applications/29">ANMAC</Link>
                                    

                                </li>
                                
                            </ul>
                        </li>
                        <li className="has-menu-child">
                            <Link to="/">Licensing</Link>
                            <ul className="sub-menu">
                                <li>
                                    <Link to="/course-details/0">Specialist and Consultant</Link>
                                    <Link to="/course-details/1">General Practitioner</Link>
                                    <Link to="/course-details/2">Dentist</Link>
                                    <Link to="/course-details/3">Nurses and Midwifes</Link>
                                    <Link to="/course-details/4">TCAM Healthcare</Link>
                                    <Link to="/course-details/5">Allied Healthcare</Link>
                                </li>
                                
                            </ul>
                        </li>
                       
                        <li>
                            <Link to="/about/">SERVICES</Link>
                        </li>
                        <li>
                            <Link to={{pathname:"/contact", hash:"#"}}>Contact Us</Link>
                        </li>
                       
                    </ul>
                </nav>
            </div>
        </header>
    );
};

export default navbar;
