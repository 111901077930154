import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import CourseItem from "../Components/CourseItem";

class CourseCarousel extends Component {
    state = {
        courses: [
            {
                image:'sp',
                id: 0,
                name: "Specialist and Consultant",
                price: 28.0,
                ratings: 5,
                studens: 220,
                enrolled: 330,
                text:"Consultant undergoes speciality training in a specific field of medicine after completing the same basic medical training as a doctor..."
            },
            {
                image:'gp',
                id: 1,
                name: "General Practitioner",
                price: 28.0,
                ratings: 5,
                studens: 220,
                enrolled: 330,
                text:"general practitioner (GP) is a medical doctor who treats acute and chronic illnesses and provides preventive care and health education to patients."
            },
            {
                image:'dentist',
                id: 2,
                name: "Dentist",
                price: 23.3,
                ratings: 5,
                studens: 120,
                enrolled: 240,
                text:
                    "Dental medicine and oral medicine, is a branch of medicine that consists of the study, diagnosis, prevention, and treatment of diseases."
            },
            {
                image:'midwifes',
                id: 3,
                name: "Nurse and the Midwifes",
                price: 23.3,
                ratings: 5,
                studens: 120,
                enrolled: 240,
                text:
                    "Nurses and midwives play a vital role in providing health services. These are the people who devote their lives to caring for mothers and children."
            },
            {
                image:'hcm',
                id: 4,
                name: "TCM Health Care",
                price: 28.0,
                ratings: 5,
                studens: 220,
                enrolled: 330,
                text:
                    "TCM means Traditional Chinese Medicine.  It is a philosophy of life to guide us to obtain a physically and mentally balance well being..."
            },
            {
                image:'alligned',
                id: 5,
                name: "Allied Healthcare",
                price: 28.0,
                ratings: 5,
                studens: 220,
                enrolled: 330,
                text:
                    "Allied Health professionals are involved with the delivery of health or related services pertaining to the identification and evaluation"
            }
            ],
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 2
            },
            992: {
                items: 3
            }
        }
    };

    render() {
        let courses = (
            <OwlCarousel
                autoplay
                autoplayTimeout={3500}
                className="test-caro"
                dots={true}
                items={3}
                loop
                margin={0}
                nav={false}
                responsive={this.state.responsive}
            >
                {this.state.courses.map(course => {
                    return (
                        <div className="col-12" key={course.id}>
                            <CourseItem
                                id={course.id}
                                name={course.name}
                                price={course.price}
                                ratings={course.ratings}
                                students={course.studens}
                                enrolled={course.enrolled}
                                text={course.text}
                                image={course.image}
                            />
                        </div>
                    );
                })}
            </OwlCarousel>
        );

        return <div className="row">{courses}</div>;
    }
}

export default CourseCarousel;
