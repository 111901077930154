import React, { Component, useState } from "react";
import Form from "../../Components/UI/Forms/Form";

const CommentForm = props => {
    const [formFields, setFormFields] = useState({
        comment: {
            elementType: "textarea",
            value: "",
            elementConfig: {
                required: true,
                placeholder: "Write Comments"
            }
        }
    });

    return <Form name="commnets" formFields={formFields} btnText="Submit" />;
};

export default CommentForm;
