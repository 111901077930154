import { combineReducers } from 'redux'
import {
  INITIALIZE,
  COMPLETED,
  FAILED
} from './actions'

function homePage(state = [], action) {
  switch (action.type) {
    case INITIALIZE:
      return [
        ...state,
        action
      ]
    case COMPLETED:
      return [
        ...state,
        action
      ]
    case FAILED:
      return [
        ...state,
        action
      ]
    default:
      return state
  }
}

const todoApp = combineReducers({
  homePage: homePage
})

export default todoApp