import React, { Component } from 'react'

class Newsletter extends Component {
    render () {
        return(
            /* <!-- Call to action section start --> */
            <section className="callto-action">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 m-auto text-center">
                            <h3>Newsletter Subscription</h3>
                            <p>Get updated with latest news</p>
                            <form className="newsletter" action="#">
                                <input type="text" placeholder="Enter your Email" />
                                <button type="submit"><i className="ti-location-arrow"></i>Subscribe</button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            /* <!-- Call to action section end --> */
        )
    }
}

export default Newsletter