import React, { Fragment } from "react";
import PageLayout from "../Page";
import About from "../../Sections/About";
import Partners from "../../Sections/Partners";

const about = props => {
    return (
            <About />

    );
};

export default about;
