import React, { Component } from 'react'
import PageLayout from '../Page'
import Ratings from '../../Components/Ratings'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import VideoCourse from '../../Containers/Curriculum/Chapter/Chapters'
import Comments from '../../Containers/Comments/Comments'
import CourseHeading from '../../Components/courseHeading'
import course from '../../Components/data/attestation'
import { connect } from 'react-redux';
import { Demo } from './form';

class Attestation extends Component {

    constructor(props) {
        super(props);
    }
    redirectToForms = () => {
        return (<Demo {...this.props} />)
    }
    render() {
        const exams = course[this.props.match.params.id];
        const keys = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 14];
        return (
            <section className="course-details">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <CourseHeading heading={exams.heading} />
                            <div className="course-curriculum">
                                <Tabs>
                                    <TabList className="nav nav-tabs">
                                        <Tab>Overview</Tab>
                                        <Tab>Enquiry</Tab>
                                    </TabList>

                                    <TabPanel>
                                        <div>
                                            <div className="tab-pane">
                                                <h4 className="tab-title">Description</h4>
                                                <p>{exams.overview.description}</p>
                                                <h5 className="tab-title">{exams.overview.requirments_heading}</h5>
                                                <ul>
                                                    {exams.overview.requirements &&
                                                        exams.overview.requirements.map((items => {
                                                            return <li>{items}</li>
                                                        }))
                                                    }
                                                </ul>
                                                <div>
                                                    <p>Note: <br></br>We use candidates own email Id and Mobile Number for all application purpose
                                                        as soon as start the application we share all details to candidates so You can
                                                        always access your account and you can check your status.For College ,
                                                        University and Hospitals in UAE and India our staff directly visit the center and
                                                        fill your documents and sent to the requiered agencies.We shall always
                                                        avalaibale and with you until complete your process</p>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        {this.redirectToForms()}
                                    </TabPanel>
                                </Tabs>
                            </div>

                        </div>

                    </div>
                </div>
            </section>
        )
    }

}
function mapStateToProps(state, nextProps) {
    return {
        exams: state.homePage[0].data.data[nextProps.match.params.id]
    }
}
export default connect(mapStateToProps)(Attestation)