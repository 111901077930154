import React, { Component } from 'react'
import PageLayout from '../Page'
import Ratings from '../../Components/Ratings'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import VideoCourse from '../../Containers/Curriculum/Chapter/Chapters'
import Comments from '../../Containers/Comments/Comments'
import CourseHeading from '../../Components/courseHeading'
import course from '../../Components/data/course'
import { connect } from 'react-redux'

// import "react-tabs/style/react-tabs.css";

class CourseDetails extends Component {

    constructor(props) {
        super(props);
    }
    render() {
        const exams = this.props.exams;
        return(
                <section className="course-details">
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-12">
                            <CourseHeading heading={exams.heading}/>
                            <div className="course-curriculum">
                            <Tabs>
                                <TabList className="nav nav-tabs">
                                    <Tab>Categories</Tab>
                                    
                                </TabList>

                                <TabPanel>
                                    <VideoCourse categories= {exams.categories}/>
                                </TabPanel>
                                
                            </Tabs>
                                </div>
                            
                        </div>
                        
                        </div>
                    </div>
                </section>
                
        )
    }
    
}
function mapStateToProps(state,nextProps) {
    return{
        exams: state.homePage[0].data.data[nextProps.match.params.id]
    }
}
export default connect(mapStateToProps)(CourseDetails)