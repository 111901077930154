import { Modal, Button, Tooltip } from 'antd';
import React, { Component } from "react";

import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemPanel
} from "react-accessible-accordion";
import Chapter from "./Chapter";

class VideoCourse extends Component {
    constructor(props){
        super(props);
        this.state = {
            visible: false,
            requirements: [],
            courseName: ""
        }
    }
    handleCancel = () =>{
        this.setState({visible: false})
    }
    handleOK = (category) =>{
        this.setState({
            requirements: [...category.requirements],
            visible: true,
            courseName: category.name
        })
    }

    render() {
        const text = <span>For more details click here.</span>;
        const chapterTuts = this.props.categories.map(category => {
            return (
                <Tooltip placement="top" title={text}>
                    <div className="col-md-4 col-sm-6 " key={category.id} onClick={()=> this.handleOK(category)}>
                            <span className="icon-list-block">{category.name}</span>
                    </div>
                </Tooltip>
            );
        });

        return (
            <React.Fragment>
            <div className="row course-align">
            {chapterTuts}
            </div>
                <Modal
                  title={"Requirements for " + this.state.courseName}
                  visible={this.state.visible}
                  onOk={this.handleCancel}
                  onCancel={this.handleCancel}
                  width={700}
                >
                      { this.state.requirements.map( (category, i) => {
                            return <li key={i}>
                            <i className="ti-hand-point-right about-padding"></i>
                                <span className="span-family">{category}</span>
                            </li>
                        })}
                </Modal>
            </React.Fragment>
            
        );
    }
}

export default VideoCourse;
