import React from "react";

const input = props => {
    // console.log(props.elementType);
    // console.log(props.elementConfig);

    let InputElement = null;

    switch (props.elementType) {
        case "input":
            InputElement = (
                <input
                    {...props.elementConfig}
                    value={props.value}
                    onChange={props.changed}
                />
            );
            break;
        case "textarea":
            InputElement = (
                <textarea
                    {...props.elementConfig}
                    value={props.value}
                    onChange={props.changed}
                />
            );
            break;
        case "select":
            InputElement = (
                <select value={props.value} onChange={props.changed}>
                    {props.elementConfig.options.map(opt => (
                        <option value={opt.value}>{opt.displayValue}</option>
                    ))}
                </select>
            );
            break;
        default:
            return null;
    }

    return InputElement;
};

export default input;
