import React from "react";

const singleFeature = props => {
    return (
        <div className="sfBox">
            <i className={props.icon}></i>
            <strong>{props.heading}</strong>
            <span>{props.text}</span>
        </div>
    );
};

export default singleFeature;
