import React from "react";

const comment = props => {
    return (
        <div className="single-comment">
            <div className="user-thumb">
                <img
                    className="avatar-small circle"
                    src={require("../../assets/images/avatar-small.png")}
                    alt=""
                />
            </div>
            <div className="comments-body">
                <h4>{props.user}</h4>
                <p>{props.body}</p>
                <a href="#">
                    <i className="ti-back-right"></i>Reply
                </a>
            </div>
        </div>
    );
};

export default comment;
