import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { HashRouter } from "react-router-dom";
import $ from "jquery";
import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import todoApp from './actions/reducers'
import { Provider } from 'react-redux'
import {completed} from "./actions/actions"
import course from './Components/data/course'

const store = createStore(
  todoApp,
  applyMiddleware(
    thunkMiddleware // lets us dispatch() functions
  )
)

store.dispatch(completed({'data':course}))
const app = (
		<HashRouter>
	    	<Provider store={store}>
	        	<App />
	    	</Provider>
    	</HashRouter>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
