import React, { Component } from "react";

class Applications extends Component {
	constructor(props){
		super(props);
		this.state = {
			applications: [
				{
					url: '#/applications/5',
					text: 'DHA',
					id: 5
				},
				{
					url: '#/applications/4',
					text: 'DOH',
					id: 4
				},
				{
					url: '#/applications/6',
					text: 'MOH',
					id: 6
				},
				{
					url: '#/applications/15',
					text: 'NCLEX RN',
					id: 15
				},
				{
					url: '#/applications/16',
					text: 'PLAB',
					id: 16
				},
				{
					url: '#/applications/17',
					text: 'VETASSESS',
					id: 17
				},
				{
					url: '#/applications/18',
					text: 'USMLE',
					id: 18
				},
				{
					url: '#/applications/19',
					text: 'HCPC',
					id: 19
				},
				{
					url: '#/applications/20',
					text: 'CORU',
					id: 20
				},
				{
					url: '#/applications/21',
					text: 'SCFHS',
					id: 21
				},
				{
					url: '#/applications/22',
					text: 'HMS',
					id: 22
				},
				{
					url: '#/applications/9',
					text: 'NHRA',
					id: 9
				},
				{
					url: '#/applications/23',
					text: 'CGFNS',
					id: 23
				},
				{
					url: '#/applications/14',
					text: 'Data Flow',
					id: 14
				},
				{
					url: '#/applications/24',
					text: 'NNAS',
					id: 24
				},
				{
					url: '#/applications/2',
					text: 'NMBI',
					id: 2
				},
				{
					url: '#/applications/1',
					text: 'APHRA',
					id: 1
				},
				{
					url: '#/applications/25',
					text: 'NHS',
					id: 25
				},
				{
					url: '#/applications/26',
					text: 'ERES',
					id: 26
				},
				{
					url: '#/applications/27',
					text: 'AES',
					id: 27
				},
				{
					url: '#/applications/28',
					text: 'WESS',
					id: 28
				},
				{
					url: '#/applications/29',
					text: 'ANMAC',
					id: 29
				},
			
				{
					url: '#/applications/3',
					text: 'NMC',
					id: 3
				},
			
			
			
				
				{
					url: '#/applications/8',
					text: 'OMSB',
					id: 8
				},
			
				{
					url: '#/applications/10',
					text: 'SCFHS',
					id: 10
				},
			
			
			
			]
		}
	}
	render(){
		const categories = this.state.applications.map(category => {
            return (
                <div className="col-md-2 col-sm-4" key={category.id}>
                    <a href={category.url} className="icon-list-block">
                        <span>{category.text}</span>
                    </a>
                </div>
            );
        });
		return(
			<section className="bg-light applications-padding">
                <div className="container">
                	<div className="row">
                        <div className="col-lg-7 col-md-9 m-auto text-center">
                            <div className="">
                                <span className="tagline">OUR SERVICES</span>
                            </div>
                        </div>
                    </div>
                	
                	<div className="row">
                	
                	{categories}
					<div className="col-md-6 col-sm-4" key={11}>
	                    <a href="#/applications/11" className="icon-list-block">
	                        <span>Good Standing Certificate/Verification Of Registration</span>
	                    </a>
	                </div>
	                <div className="col-md-6 col-sm-4" key={12}>
	                    <a href="#/applications/12" className="icon-list-block">
	                        <span>Registration Renewal or Transfer/License Transfer</span>
	                    </a>
	                </div>
	                {/* <div className="col-md-4 col-sm-4" key={13}>
	                    <a href="#/applications/13" className="icon-list-block">
	                        <span>Documents Attestation</span>
	                    </a>
	                </div> */}
                  	</div>
                </div>
            </section>

		)
	}	
}

export default Applications;