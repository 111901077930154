import React, { Component } from "react";
import CountUp from "react-countup";

class Funfacts extends Component {
    state = {
        funfacts: [
            {
                id: 0,
                count: 291,
                title: "Amazing Courses"
            },
            {
                id: 1,
                count: 38,
                title: "talented instructors"
            },
            {
                id: 2,
                count: 3940,
                title: "Skilled Students"
            },
            {
                id: 3,
                count: 14,
                title: "categories"
            }
        ]
    };

    render() {
        const funfacts = this.state.funfacts.map(fun => {
            return (
                <li key={fun.id}>
                    <CountUp start={0} end={fun.count} duration={5} />
                    <p>{fun.title}</p>
                </li>
            );
        });

        return (
            /* <!-- Funfacts start --> */
            <section
                className={
                    this.props.className ? this.props.className : "funfacts"
                }
            >
                <div className="container">
                    <ul>{funfacts}</ul>
                </div>
            </section>
            /* <!-- Funfacts end --> */
        );
    }
}

export default Funfacts;
