import React from "react";
import { Route, Switch, Redirect, HashRouter, } from "react-router-dom";
import Layout from "./Layouts/Layout";
import PrivateLayout from "./Layouts/privateLayout";
import HomeTwo from "./Layouts/Homepages/HomeTwo";
import About from "./Layouts/Pages/About";
import Courses from "./Layouts/Pages/Courses";
import CourseDetails from "./Layouts/Pages/CourseDetails";

import Contact from "./Layouts/Pages/Contact";
import Thankyou from "./Layouts/Pages/Thankyou";
import ApplicationDetails from "./Layouts/Pages/Applications";
import Attestation from "./Layouts/Pages/Attestation";
import Health from "./Layouts/Pages/HealthFacility";
// All external css
import "./assets/css/bootstrap.min.css";
import "./assets/css/fontawesome.min.css";
import "./assets/css/themify-icons.css";
import "./assets/css/style.css";
import "./assets/css/custom.css";
import 'antd/dist/antd.css';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <HashRouter>
      <Route {...rest} render={(props) => (
        <PrivateLayout> <Component {...props} /> </PrivateLayout>
      )} />
  </HashRouter>
)

const CommonRoute = ({ component:Component, ...rest }) => (
    <HashRouter>
        <Layout>
            <Route {...rest} render={(props) => (
                <Component {...props} />
              )}/>
        </Layout>
    </HashRouter>
)

function App() {
    return (
        <div className="App">
                
                    <Switch>
                            <CommonRoute path="/" exact component={HomeTwo} />
                            
                            <CommonRoute path="/about" component={About} />
                            <CommonRoute path="/courses" component={Courses} />
                            <CommonRoute path="/course-details/:id" component={CourseDetails} />
                            
                      
                            <CommonRoute path="/applications/:id" component={ApplicationDetails} />
                            <CommonRoute path="/health/:id" component={Health} />
                            
                            <CommonRoute path="/attestation/:id" component={Attestation} />
                            <CommonRoute path="/bussiness_setup/:id" component={Attestation} />
                           
                            
                            <CommonRoute path="/contact" component={Contact} />
                            <CommonRoute path="/thankyou" component={Thankyou} />    
                    </Switch>
            
        </div>
    );
}

export default App;
