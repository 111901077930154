import React from "react";

const posts = props => {
    const { id, meta, title } = props;

    return (
        <div className="post-item">
            <img
                src={require("../../assets/images/post/" + id + ".jpg")}
                alt=""
            />
            <div className="post-content">
                <div className="meta-tags">
                    <a href="#" className="post-meta category">
                        {meta.categories.join(", ")}
                    </a>{" "}
                    |{" "}
                    <a href="#" className="post-meta date">
                        {meta.created}
                    </a>
                </div>
                <h3 className="post-title">
                    <a href="#">{title}</a>
                </h3>
                <div className="meta-tags">
                    <a href="#" className="post-meta category">
                        <i className="ti-package"></i>
                        {meta.tags}
                    </a>
                    <a href="#" className="post-meta commentCount">
                        <i className="ti-comments"></i>2 Comments
                    </a>
                </div>
            </div>
        </div>
    );
};

export default posts;
