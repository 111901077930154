import React, { Component } from "react";
import Form from "./Form";
import { performRequest } from "../../../Services/api-handler";
import { success, error} from "../../message";
import { Spin, Alert } from 'antd';
class ContactForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            formFields: {
                name: {
                    elementType: "input",
                    value: "",
                    elementConfig: {
                        type: "text",
                        required: true,
                        placeholder: "Full Name"
                    }
                },
                email: {
                    elementType: "input",
                    value: "",
                    elementConfig: {
                        type: "email",
                        required: true,
                        placeholder: "Your email"
                    }
                },
                number: {
                    elementType: "input",
                    value: "",
                    elementConfig: {
                        type: "text",
                        required: true,
                        placeholder: "Your WhatsApp Number"
                    }
                },
                body_text: {
                    elementType: "textarea",
                    value: "",
                    elementConfig: {
                        required: true,
                        placeholder: "Your message"
                    }
                }
            },
            showLoader: false
        };
    }
   
    getFormDetails = (data) =>{
        this.setState({showLoader: true});
        data.heading = 'Contact us'
        performRequest('post', '/v1',data, true).
        then(res=>{
            this.setState({showLoader: false});
            success('Your query send successfully.You can expect a mail by soon');
            this.props.history.push('/thankyou');
        }).catch(err=>{
            alert(err)
            this.setState({showLoader: false});
            error('Sorry for inconvenience caused.');
        })
    };
    render() {
        return (
            <React.Fragment>
            
            <div className="form">
                {(this.state.showLoader)?
                <Spin tip="Sending...">
                    <Alert
                    message="Thanks for contacting us."
                    description="We will contact you as soon as possible."
                    type="info"
                    />
                </Spin>:""}
                <Form
                    name="contacts"
                    formFields={this.state.formFields}
                    inputCol="col-md-6"
                    btnText="send message"
                    getFormDetails={this.getFormDetails}
                />
            </div>
            </React.Fragment>
        );
    }
}

export default ContactForm;
