import React, { Component } from 'react'
import { Link } from "react-router-dom";

class CourseHeading extends Component {

	render(){
		console.log(this.props)
		return(
					<div className="course-content">
                        <h3>{this.props.heading.heading}</h3>
						<div className="row">                        
	                        <div className="col-lg-9">
	                        	<p>{this.props.heading.description}</p>
	                        </div>
	                        <div className="col-lg-3">
	                        	<Link to="/contact/">For any querys click here...</Link>
	                		</div>
                		</div>
                        <div className="contact-details">
                        	
                        </div>
                        
                    </div>
			)
	}
}

export default CourseHeading;