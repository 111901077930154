/*
 * action types
 */

export const INITIALIZE = 'INITIALIZE'
export const COMPLETED = 'COMPLETED'
export const FAILED = 'FAILED'

/*
 * action creators
 */

export function initialize(data=[]) {
  return { type: INITIALIZE, message:'start' }
}

export function completed(data) {
  return { type: COMPLETED, data:data, message:'success' }
}

export function failed(error) {
  return { type: FAILED, error: error, message:'failed' }
}