import React, { Component } from "react";
import SingleFeature from "../Components/SingleFeature";

class FeatureBox extends Component {
    state = {
        features: [
            {
                id: 0,
                icon: "fas fa-drafting-compass",
                heading: "100,000 online courses",
                text: "Explore a variety of fresh topics"
            },
            {
                id: 1,
                icon: "far fa-user-circle",
                heading: "Instant Expert instruction",
                text: "Find the right instructor for you"
            },
            {
                id: 2,
                icon: "fas fa-history",
                heading: "Lifetime course access",
                text: "Learn on your schedule anytime"
            }
        ]
    };

    render() {
        const features = this.state.features.map(feature => {
            return (
                <div className="col-md-4" key={feature.id}>
                    <SingleFeature
                        icon={feature.icon}
                        heading={feature.heading}
                        text={feature.text}
                    />
                </div>
            );
        });

        return (
            /* Feature Box section start */
            <section
                className={
                    "features " + (this.props.type ? this.props.type : "")
                }
            >
                <div className="container">
                    {/* 
                        <div className="row">
                            <div className="col-md-4">
                                <div className="sfBox">
                                    <i className="fas fa-drafting-compass"></i>
                                    <strong>100,000 online courses</strong>
                                    <span>Explore a variety of fresh topics</span>
                                </div>
                            </div>
                            <div className="col-md-4 d-md-flex justify-content-center">
                                <div className="sfBox">
                                    <i className="far fa-user-circle"></i>
                                    <strong>Expert instruction</strong>
                                    <span>Find the right instructor for you</span>
                                </div>
                            </div>
                            <div className="col-md-4 d-md-flex justify-content-md-end">
                                <div className="sfBox mb-0">
                                    <i className="fas fa-history"></i>
                                    <strong>Lifetime access</strong>
                                    <span>Learn on your schedule</span>
                                </div>
                            </div>
                        </div> 
                    */}
                    <div className="row">{features}</div>
                </div>
            </section>
            /* Feature Box section start */
        );
    }
}

export default FeatureBox;
