import React, { Component } from 'react'
import $ from 'jquery'
require('jquery-countdown');

class Clock extends Component {

    componentDidMount() {
        this.$el = $(this.el)

        this.$el.countdown(this.props.date).on('update.countdown', function(event) {
            var $this = $(this).html(event.strftime(''
              + '<p><span>%-d</span> Day%!d</p> '
              + '<p><span>%H</span> Hour%!d</p> '
              + '<p><span>%M</span> Min%!d</p> '
              + '<p><span>%S</span> Sec%!d</p>'));
          });
    }

    render() {
        return (
            <div className="clock" ref={el => this.el = el}></div>
        )
    }
}

export default Clock