import axios from 'axios';
// export const API_BASE_URL = 'http://127.0.0.1:8000/';
export const API_BASE_URL = 'https://uwhl8qo9uj.execute-api.ap-south-1.amazonaws.com/'
export const performRequest = async (method, url='v1', params, auth=false) => {
    
    const body = method === 'get' ? 'params' : 'data'
    const config = {
        method,
        url,
        baseURL: API_BASE_URL,
        [body]: params || {}
    }
    config.headers = {
        "Content-Type": "application/json; charset=utf-8",
        "x-api-key": "JfFcOBarP19L9INXdkZld8abdjgBs1EW88tukGpX"
    }
    if(auth == false){
        config.headers.Authorization = "Bearer " + localStorage.getItem('token')
    }
    
    return axios.request(config); 
}