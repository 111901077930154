import React, { Component, Fragment } from "react";
import Comment from "./Comment";
import CommentFrom from "./CommentFrom";

class Comments extends Component {
    state = {
        comments: [
            {
                id: 0,
                user: "Kosmi Kotalia",
                body:
                    "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which.",
                replys: [
                    {
                        id: 0,
                        user: "Adam wood",
                        body:
                            "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maxime ratione libero temporibus quo id iste, esse at accusamus atque qui."
                    }
                ]
            },
            {
                id: 1,
                user: "Lina handshake",
                body:
                    "Asperiores minus, laborum incidunt explicabo dolore officiis praesentium veniam expedita libero harum pariatur reiciendis porro debitis aliquam quas labore ducimus nisi. Blanditiis?"
            }
        ]
    };

    render() {
        const comments = this.state.comments.map(cmnt => {
            return (
                <li key={cmnt.id}>
                    <Comment user={cmnt.user} body={cmnt.body} />
                    {cmnt.replys ? (
                        <ul className="cmnt-level-2">
                            {cmnt.replys.map(r => {
                                return (
                                    <li key={r.id}>
                                        <Comment user={r.user} body={r.body} />
                                    </li>
                                );
                            })}
                        </ul>
                    ) : null}
                </li>
            );
        });

        return (
            <Fragment>
                <div className="comments">
                    <h4 className="comment-title">comments</h4>
                    <ul>{comments}</ul>
                </div>
                <div className="comment-form">
                    <h4 className="comment-title">Leave a comment</h4>
                    <CommentFrom />
                </div>
            </Fragment>
        );
    }
}

export default Comments;
