import React, { Component } from "react";
import axios from "../../../axios-orders";
import Input from "../Input";
import { withRouter } from "react-router-dom";

class Form extends Component {
    state = {
        formFields: this.props.formFields,
        submited: false
    };

    inputChangeHandler = (event, inputIdentifier) => {
        // Cloning the state
        const updatedFormFields = {
            ...this.state.formFields
        };

        // Deep cloning the state property
        const updatedFormElem = {
            ...updatedFormFields[inputIdentifier]
        };

        // Updating property value based on `inputIdentifier`
        updatedFormElem.value = event.target.value;

        // updating form field
        updatedFormFields[inputIdentifier] = updatedFormElem;

        this.setState({
            formFields: updatedFormFields
        });
    };

    // Handle form submission
    formSubmissionHandler = e => {
        e.preventDefault();

        const formData = {};
        for (let formIndetifier in this.state.formFields) {
            formData[formIndetifier] = this.state.formFields[
                formIndetifier
            ].value;
        }

        /*
         * Check form type before sending form data
         * Form types are: `appoinment`, `trial`
         * Database table name will be same as types name
         */
        const formName = this.props.name;
        this.props.getFormDetails(formData);
        
    };

    render() {
        let formElementArray = [];
        for (let key in this.state.formFields) {
            formElementArray.push({
                id: key,
                config: this.state.formFields[key]
            });
        }

        return (
            <form onSubmit={this.formSubmissionHandler}>
                <div className="row">
                    {formElementArray.map(formElem => {
                        return (
                            <div
                                key={formElem.id}
                                className={
                                    !this.props.inputCol ||
                                    formElem.config.elementType === "textarea"
                                        ? "col-12"
                                        : this.props.inputCol
                                }
                            >
                                <Input
                                    elementType={formElem.config.elementType}
                                    changed={event => {
                                        this.inputChangeHandler(
                                            event,
                                            formElem.id
                                        );
                                    }}
                                    value={formElem.config.value}
                                    elementConfig={
                                        formElem.config.elementConfig
                                    }
                                />
                            </div>
                        );
                    })}
                </div>

                <button
                    type="submit"
                    className={
                        this.props.name === "contacts"
                            ? "btn btn-default"
                            : "btn btn-filled"
                    }
                >
                    {this.props.btnText ? this.props.btnText : "Submit"}
                </button>
            </form>
        );
    }
}

export default withRouter(Form);
