import React, { Component } from "react";
import Slide from "./Slide/Slide";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

class Slider extends Component {
    render() {
        const options = [
            "All Categories",
            "IT & Software",
            "Development",
            "Marketing & SEO"
        ];
        const defaultOption = options[0];

        return (
            /* Slider section start  */
            <section className="slider-banner p-0">
                <Slide />
                <div className="container">
                    <div className="col-md-11 slider-content m-auto text-center">
                        <h2>
                            <span>We are well professional on Health facility and professional new
                                registration and renewal </span>
                        </h2>
                        {/* <form className="search-form" action="#">
                         
                            <Dropdown
                                className="nice-select"
                                options={options}
                                onChange={this._onSelect}
                                value={defaultOption}
                                placeholder="Select an option"
                            />

                            <input type="text" placeholder="Enter a Subject" />
                            <button type="submit">
                                <i className="ti-search"></i>
                            </button>
                        </form> */}
                    </div>
                </div>
            </section>
            /* Slider section end */
        );
    }
}

export default Slider;
