import React from "react";
import { Link } from "react-router-dom";

const Thankyou = props => {
    return (
        <div className="container">
            <div className="notice-board">
                <h1>
                    Thank You<span>!</span>
                </h1>
                <h6>We have receive your email. Stay tuned for updates.</h6>
                <p>
                    Please make sure that your are given your correct WhatsApp number.There may be chance to get contact through WhatsApp also.
                </p>
                <Link to="/" className="btn btn-default">
                    Back to Home
                </Link>
            </div>
        </div>
    );
};

export default Thankyou;
