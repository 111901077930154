import React, { Component } from "react";
import PageLayout from "../Page";
import CourseItem from "../../Components/CourseItem";

class Courses extends Component {
    state = {
        courses: [
            {
                id: 0,
                name: "HTML5 for beginners",
                price: 28.0,
                ratings: 5,
                studens: 220,
                enrolled: 330,
                text:
                    "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam dicta at aliquam..."
            },
            {
                id: 1,
                name: "Advance CSS3 animations",
                price: 12.4,
                ratings: 5,
                studens: 55,
                enrolled: 130,
                text:
                    "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam dicta at aliquam..."
            },
            {
                id: 2,
                name: "Core Javascript basics",
                price: 23.3,
                ratings: 5,
                studens: 120,
                enrolled: 240,
                text:
                    "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam dicta at aliquam..."
            },
            {
                id: 3,
                name: "Aplied Physics application",
                price: 40.2,
                ratings: 5,
                studens: 17,
                enrolled: 53,
                text:
                    "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam dicta at aliquam..."
            },
            {
                id: 4,
                name: "Introduction to quantum mechanics",
                price: 33.0,
                ratings: 5,
                studens: 220,
                enrolled: 330,
                text:
                    "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam dicta at aliquam..."
            },
            {
                id: 5,
                name: "Chemistry periodic table",
                price: 23.4,
                ratings: 5,
                studens: 220,
                enrolled: 330,
                text:
                    "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam dicta at aliquam..."
            },
            {
                id: 6,
                name: "Communication engineering",
                price: 60.02,
                ratings: 5,
                studens: 232,
                enrolled: 243,
                text:
                    "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam dicta at aliquam..."
            },
            {
                id: 7,
                name: "Telecommunication systems",
                price: 75.0,
                ratings: 5,
                studens: 20,
                enrolled: 104,
                text:
                    "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam dicta at aliquam..."
            },
            {
                id: 8,
                name: "Marketing management strategies",
                price: 43.0,
                ratings: 5,
                studens: 20,
                enrolled: 156,
                text:
                    "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam dicta at aliquam..."
            }
        ]
    };

    render() {
        const courses = this.state.courses.map(course => {
            return (
                <div className="col-lg-4 col-md-6" key={course.id}>
                    <CourseItem
                        id={course.id}
                        name={course.name}
                        price={course.price}
                        ratings={course.ratings}
                        students={course.studens}
                        enrolled={course.enrolled}
                        text={course.text}
                    />
                </div>
            );
        });

        return (
                <section className="courses">
                    <div className="container">
                        <div className="row">{courses}</div>
                        <div className="pagination">
                            {/* <a href="#">prev</a> */}
                            <span className="current-page">1</span>
                            <a href="$">2</a>
                            <a href="$">3</a>
                            <a href="$">4</a>
                            <a href="$">Next</a>
                        </div>
                    </div>
                </section>
                
        );
    }
}

export default Courses;
