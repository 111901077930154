import React, { Component } from "react";

class HealthFacility extends Component {
	constructor(props){
		super(props);
		this.state = {
			applications: [
				{
					url: '#/health/1',
					text: 'registration for medical equipment',
					id: 1,
					size: 6
				},
				{
					url: '#/health/2',
					text: 'Registration for medical precursor companies',
					id: 2,
					size: 6
				},
				{
					url: '#/health/3',
					text: 'Change the name of private health facilities',
					id: 3,
					size: 6
				},
				{
					url: '#/health/4',
					text: 'Issue of 24-hour pharmacy work permit',
					id: 4,
					size: 6
				},
				{
					url: '#/health/5',
					text: 'Issue of permit to import medicines from a local agent',
					id: 5,
					size: 6
				},
				{
					url: '#/health/6',
					text: 'Adding new specialty to Private health facilities',
					id: 6,
					size: 6
				},
				{
					url: '#/health/7',
					text: 'Apply for healthy restaurant accreditation ',
					id: 7,
					size: 6
				},
				{
					url: '#/health/8',
					text: 'Registration for manufacture medical products',
					id: 8,
					size: 6
				},
				{
					url: '#/health/9',
					text: 'Changing the location of private medical facility',
					id: 9,
					size: 6
				},
				{
					url: '#/health/10',
					text: "Cancellation of doctor's license",
					id: 10,
					size: 6
				},
				{
					url: '#/health/11',
					text: 'permit to import medical equipment',
					id: 11,
					size: 5
				},
				{
					url: '#/health/12',
					text: 'Changing the licensed ownership of private medical facilites in MOH',
					id: 12,
					size: 7
				},
				{
					url: '#/health/13',
					text: 'License for a Healthcare Advertisement on a Website or Digital Link',
					id: 12,
					size: 7
				},
				
				
			]
		}
	}
	render(){
		const categories = this.state.applications.map(category => {
            return (
                <div className={`col-md-${category.size} col-sm-${category.size}`} key={category.id}>
                    <a href={category.url} className="icon-list-block">
                        <span>{category.text}</span>
                    </a>
                </div>
            );
        });
		return(
			<section className="bg-light applications-padding">
                <div className="container">
                	<div className="row">
                        <div className="col-lg-7 col-md-9 m-auto text-center">
                            <div className="">
                                <span className="tagline">Health facility and professional Registration & Renewal service</span>
                            </div>
                        </div>
                    </div>
                	
                	<div className="row">
                	
                	{categories}
					
                  	</div>
                </div>
            </section>

		)
	}	
}

export default HealthFacility;