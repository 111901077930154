import React, { Fragment } from "react";
import Slider from "../../Containers/Slider/Slider";
import Features from "../../Sections/Features";
import Categories from "../../Sections/Categories";
import Applications from "../../Sections/applications";
import Attestation from "../../Sections/attestation";
import BusinessSetUp from "../../Sections/bussinessSetup";



import Trial from "../../Sections/Trial";
import PopularCourses from "../../Sections/PopularCourses";
import Funfacts from "../../Sections/Funfacts";
import Blog from "../../Sections/Blog";
import Testimonial from "../../Sections/Testimonial";
import Newsletter from "../../Sections/Newsletter";
import HealthFacility from "../../Sections/healthFacility"
import { Floating } from "../../Sections/floating";


const homeTwo = props => {
    return (
        <Fragment>
            
            <Floating title="health license" text="Forin Plus" />
            <Slider />
            <HealthFacility />
            <Applications />
            <Attestation />
            <BusinessSetUp />
            <PopularCourses />
            <Categories />
        </Fragment>
    );
};

export default homeTwo;
