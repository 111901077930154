import React from "react";
import { Link } from "react-router-dom";
import Ratings from "../Components/Ratings";

const courseItem = props => {
console.log(props.image + 1)

    return (
        <div className="single-course">
            <figure className="course-thumb">
                <img
                    src={require("../assets/images/course/" +
                        (props.image) +
                        ".jpg")}
                    alt=""
                />
                {/*<strong className="ribbon">${props.price}</strong>*/}
            </figure>
            <div className="course-content">
                <h3>
                    <Link to={"course-details/"+props.id}>{props.name}</Link>
                </h3>
                <p>{props.text}</p>
                <div className="enroll">
                    {/* <span className="total-students">
                        <i className="ti-user"></i> {props.students}
                    </span> */}
                    {/*<Ratings ratings={props.ratings} views={props.enrolled} />
                    <div className="course-meta text-right">
                        <Link to="" className="btn btn-filled">
                            Enroll now
                        </Link>
                    </div>
                    */}
                </div>
            </div>
        </div>
    );
};

export default courseItem;
