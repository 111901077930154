import { Form, Input, Button, Checkbox, Col, Row, InputNumber, Select } from 'antd';
import React, { Component, useState  } from 'react';
import { DatePicker } from 'antd';
import { performRequest } from "../../Services/api-handler";
import { Spin, Alert } from 'antd';
import { success, error} from "../../Components/message";

const { Option } = Select;
export const Demo = (props) => {
  const [showLoader, setShowLoader] = useState(false);
  const onFinish = values => {
    setShowLoader(true);
    values.applications = parseInt(props.match.params.id);
    values.heading = 'Contact us'
     performRequest('post', "/v1", values, true).
        then(res=>{
          setShowLoader(false);
          success('Your query send successfully.You can expect a mail by soon');
        }).
        catch(err=>{
            if(err.response.status === 400){
              error('You have already done enquiry on this applications');
            }else{
              error('Sorry for inconvenience caused.');
            }
            setShowLoader(false);
        })
  };
  const onFinishFailed = errorInfo => {
  };
  const [form] = Form.useForm();
  const validateMessages = {
    required: '${label} is required!'
  };
  const initialValues = {};
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 10 },
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 10 },
  };
  const validateEmail = (rule: any, value: any, callback: any) =>{
    if(value){
      value = value.replace(/\s/g, "");
      var phoneno = /^\+?([0-9]{3})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{5})$/;
      if(value.match(phoneno)){
        callback();
      }else{
        callback("Phone number is not valid");
      }
    }else{
      callback();
    }
  }
  const mobileWrapper = {
    wrapperCol: { span: 4 },
  }
  const { TextArea } = Input;
  return (
    <React.Fragment>
    {showLoader?<Spin tip="Sending...">
        <Alert
        message="Thanks for contacting us."
        description="We will contact you as soon as possible."
        type="info"
        />
    </Spin>:""}
    <Form {...layout}
      onFinish={onFinish}
      form={form} name="packages"
      initialValues={props.initialValues}
      validateMessages={validateMessages}
      className="sb-form"
    >
      <div className="container">
        <div className="form-notice">
          <h6>Please provide following information so that we can verify the details and get back to you as soon as possible.</h6>
        </div>
      </div>
           <Form.Item
              label="Name Of The candidate"
              name="name"
              rules={[{ required: true, message: 'Please enter your name!' }]}
            >
              <Input />
            </Form.Item>
           <Form.Item
              label="Email"
              name="email"
              rules={[{
                        type: 'email', message: 'The input is not valid email',
                      }, {
                        required: true, message: 'Please input your email',
                      }]}
            >
              <Input />
            </Form.Item>
           <Form.Item
              label="Whatsapp Number"
              name="number"
              rules={[{ required: true, message: 'Please enter your Whatsapp Number!' },
                      { validator: validateEmail}
                      ]}
            >
              <Input placeholder="enter country code also example(00971 502 297 711"/>
            </Form.Item>
            <Form.Item
              label="Message"
              name="body_text"
              rules={[{ required: true, message: 'Please enter your querys!' }]}
            >
              <TextArea rows={4}/>
            </Form.Item>
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">Save</Button>
        </Form.Item>
    </Form>
    </React.Fragment>
  );
};