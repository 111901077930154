import React, { Component } from "react";

class Attestation extends Component {
	constructor(props){
		super(props);
		this.state = {
			applications: [
				{
					url: '#/attestation/1',
					text: 'MEA Attestation',
					id: 1
				},
				{
					url: '#/attestation/2',
					text: 'HRD Attestation',
					id: 2
				},
				{
					url: '#/attestation/3',
					text: 'MOFA Attestation',
					id: 3
				},
				{
					url: '#/attestation/4',
					text: 'Embassy Attestation',
					id: 4
				},
				{
					url: '#/attestation/5',
					text: 'UAE Attestation',
					id: 5
				},
			
			]
		}
	}
	render(){
		const categories = this.state.applications.map(category => {
            return (
                <div className="col-md-4 col-sm-4" key={category.id}>
                    <a href={category.url} className="icon-list-block">
                        <span>{category.text}</span>
                    </a>
                </div>
            );
        });
		return(
			<section className="bg-light applications-padding">
                <div className="container">
                	<div className="row">
                        <div className="col-lg-7 col-md-9 m-auto text-center">
                            <div className="">
                                <span className="tagline">ATTESTATION</span>
                            </div>
                        </div>
                    </div>
                	
                	<div className="row">
                	
                	{categories}
					
                  	</div>
                </div>
            </section>

		)
	}	
}

export default Attestation;