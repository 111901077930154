import React from 'react'
import CourseCarousel from '../Components/CourseCarousel'


const popularCourses = props => {
    return (
        /* Courses section start */
        <section className="courses">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 col-md-9 m-auto text-center">
                        <div className="sec-heading">
                        <h3 className="sec-title">Popular Courses</h3>
                        </div>
                    </div>
                </div>        
                <CourseCarousel />
            </div>
        </section>
        /* Courses section end */
    )
}

export default popularCourses