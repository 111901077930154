// import FloatingLabel from "floating-label-react";
import React from 'react';
// import $ from "jquery";

export const Floating = () =>{
	return(
		<marquee className="marguee" behavior="scroll" direction="left" onmouseover="this.stop();" onmouseout="this.start();">
		Good Standing Certificate/Verification Of Registration, Registration Renewal or Transfer/License Transfer, Documents Attestation,
		Australian Health Practitioner Regulation Agency(APHRA), Nursing and Midwifery Board of Ireland(NMBI), 
		Nursing and Midwifery Council of United Kingdom(NMC).,
		Department of health before its called HAAD Health authority of Abudhabi(DOH).,
		Dubai Health Authority(DHA), Ministry of Health and Prevention UAE(MOH),
		Hamad Medical Corporation is a public healthcare provider in the State of Qatar(HMC).,
		Oman Medical Speciality Board(OMSB).,
		National Health Regulatory Authority – Bahrain(NHRA).,
		Saudi Commission For Health Specialties(SCFHS),
		Data Flow Verification.
		</marquee>

	)
}

// const {useState, useEffect} = React;
// const width = 1000

// export const Floating = ({title, text}) => {
//     const [pos,setPos] = useState(0);   
//     const [run, setRun] = useState(true);
//     const scrollEff = () => {
//         if(run) setPos(p=>p<width? p+1: -width);        
//     }
    
//     useEffect(() => {
//         const tm = setTimeout(scrollEff, 10);
//         return () => clearTimeout(tm);
//     },[pos]);
    
//     const onMouseEnter = (e) => {
//         // console.log("mouse enter");
//         setRun(false);
//     }
    
//     const onMouseLeave = (e) => {
//         // console.log("mouse leave");
//         setRun(true);
//         setPos(pos+1); // to trigger useEffect 
//     }
    
//     const styles = {
//         position: "relative", 
//         fontSize: "1em",
//         left: pos + "px"
//     };
    
//     return (
//         <h1 style={styles} 
//             onMouseEnter={onMouseEnter} 
//             onMouseLeave={onMouseLeave} 
//         ><mark>{title}</mark> {text}</h1>
//     )
// }