import React from "react";

const about = props => {
    const requirements = [
            {
                url:'#/applications/1',
                text:'Australian Health Practitioner Regulation Agency(APHRA)',
                id: 1
            },
            {
                url:'#/applications/2',
                text:'Nursing and Midwifery Board of Ireland(NMBI)',
                id: 2
            },
            {
                url:'#/applications/3',
                text:'Nursing and Midwifery Council of United Kingdom(NMC).',
                id: 3
            },
            {
                url:'#/applications/4',
                text:'Department of health before its called HAAD Health authority of Abudhabi(DOH).',
                id: 4
            },
            {
                url:'#/applications/5',
                text:'Dubai Health Authority(DHA)',
                id: 5
            },
            {
                url:'#/applications/6',
                text:'Ministry of Health and Prevention UAE(MOH)',
                id: 6
            },
            {
                url:'#/applications/7',
                text:'Hamad Medical Corporation is a public healthcare provider in the State of Qatar(HMC).',
                id: 7
            },
            {
                url:'#/applications/8',
                text:'Oman Medical Speciality Board(OMSB).',
                id: 8
            },
            {
                url:'#/applications/9',
                text:'National Health Regulatory Authority – Bahrain(NHRA).',
                id: 9
            },
            {
                url:'#/applications/10',
                text:'Saudi Commission For Health Specialties(SCFHS).',
                id: 10
            },
            {
                url:'#/applications/11',
                text:'Good Standing Certificate/Verification Of Registration(GSC/V).',
                id: 11
            },
            {
                url:'#/applications/12',
                text:'Registration Renewal or Transfer/License Transfer(RRT/LT).',
                id: 12
            },
            {
                url:'#/applications/13',
                text:'Documents Attestation.',
                id: 13
            },
            {
                url:'#/applications/14',
                text:'Data flow verification.',
                id: 14
            }
            ];
    return (
        // About section start
        <section
            className={
                "about" + (props.sectionClass ? " " + props.sectionClass : "")
            }
        >
            <div className={"container" + (props.fluid ? "-fluid" : "")}>
                <div className="row">
                    <div className="col-lg-12 col-md-9 m-auto text-center">
                        <div className="sec-heading">
                            <span className="tagline">About us</span>
                            <h3 className="sec-title">
                                We provide application registration and renewal for health practitioners.
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-md-12">
                        <div
                            className={
                                "entry-content" +
                                (props.fluid ? "-fluid ml-auto" : "")
                            }
                        >
                            <h3>
                                Services
                            </h3>
                            <p>
                                we ensure that only health practitioners with the skills and qualifications to provide competent and ethical care are registered to practise. We also manage registration and renewal processes for local and overseas qualified health practitioners and manage student registration.
                            </p>
                            <h3>
                                Policy
                            </h3>
                            <p>
                            We are fully responsible for the application which we do from the beginning. 
The application time depends upon the government facility policy, we would say shall estimated time. The duration of the application may change depends on regulatory board changes. Any new changes come after starting the process that is the responsibility of the candidate to full fill the new requirement.
We are not providing any recruitment or immigration work and us not facilitating any jobs.
                            </p>
                            <h6>
                                We provide registration and renewal's in following agencys
                            </h6>
                            <ul>
                                { requirements.map( (category, i) => {
                                    return <li key={i}><i className="ti-hand-point-right about-padding"></i>
                                        <a href={category.url} >
                                            <span>{category.text}</span>
                                        </a>
                                    </li>
                                })}
                            </ul>
                            <p>
                                
                            </p>
                        </div>
                    </div>
                    {/*<div className="col-md-6">
                        /<div className="video-box">
                            <img
                                src={require("../assets/images/video-bg.jpg")}
                                alt=""
                            />
                            <a href="#" className="video-btn">
                                <img
                                    src={require("../assets/images/youtube.png")}
                                    alt=""
                                />
                            </a>
                        </div>
                    </div>*/}
                </div>
            </div>
        </section>
        // About section end
    );
};

export default about;
