import React, { Fragment } from "react";
import { Link, useParams, withRouter } from "react-router-dom";

const page = props => {
    // console.log(props);
    return (
        <Fragment>
            {/* Page feature start */}
            <section className="page-feature">
                <div className="container text-center">
                    <h2>
                        {props.history.location.pathname.replace(
                            /[^a-zA-Z ]/g,
                            " "
                        )}
                    </h2>
                    <div className="breadcrumb">
                        <Link to="/">Home</Link>
                        <span>
                            /
                            {props.history.location.pathname.replace(
                                /[^a-zA-Z ]/g,
                                " "
                            )}
                        </span>
                    </div>
                </div>
            </section>
            {/* Page feature end */}

            {props.children}
        </Fragment>
    );
};

export default withRouter(page);
