import React, { Component } from "react";
import Posts from "../Containers/Posts/Posts";

class Blog extends Component {
    render() {
        // console.log(this.state.posts)
        return (
            <section className="blog">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-9 m-auto text-center">
                            <div className="sec-heading">
                                <span className="tagline">What’s new</span>
                                <h3 className="sec-title">
                                    Every Single Update From <br />
                                    Our Blog Page
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <Posts postLimit="3" />
                    </div>
                </div>
            </section>
        );
    }
}

export default Blog;
