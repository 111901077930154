import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";

class Testimonial extends Component {
    render() {
        return (
            /* <!-- Testimonial section start --> */
            <section className="testimonial">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-9 m-auto text-center">
                            <div className="sec-heading">
                                <span className="tagline text-white">
                                    Testimonials
                                </span>
                                <h3 className="sec-title text-white">
                                    What Our Users Say About Us
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-10 col-md-12 m-auto">
                            <div className="testimonialBox">
                                <span className="quote-sign">
                                    <i className="ti-quote-left"></i>
                                </span>

                                <OwlCarousel
                                    className="test-caro"
                                    autoplay={true}
                                    loop={true}
                                    items="1"
                                    dots={false}
                                >
                                    <div className="single-testimonial">
                                        <p>
                                            Lorem ipsum dolor sit amet,
                                            consectetur adipiscing elit, sed do
                                            eiusmod tempor incididunt ut labore
                                            eco dolore magna aliqua. Quis ipsum
                                            suspendisse ultrices gravida. Risus
                                            commodo viverra maecenas accumsan
                                            lacus vel facilisis.
                                        </p>
                                        <div className="testimonial-user">
                                            <img
                                                src={require("../assets/images/avatar-small.png")}
                                                className="avatar-small circle"
                                                alt=""
                                            />
                                            <strong>Sansa Stark</strong>
                                        </div>
                                    </div>
                                    <div className="single-testimonial">
                                        <p>
                                            Ypsum dolor sit amet, consectetur
                                            adipiscing elit, sed do eiusmod
                                            tempor incididunt ut labore eco
                                            dolore magna aliqua. Quis ipsum
                                            suspendisse ultrices gravida. Risus
                                            commodo viverra maecenas accumsan
                                            lacus vel facilisis.
                                        </p>
                                        <div className="testimonial-user">
                                            <img
                                                src={require("../assets/images/avatar-small.png")}
                                                className="avatar-small circle"
                                                alt=""
                                            />
                                            <strong>Linda heiday</strong>
                                        </div>
                                    </div>
                                    <div className="single-testimonial">
                                        <p>
                                            Qonsectetur adipiscing elit, sed do
                                            eiusmod tempor incididunt ut labore
                                            eco dolore magna aliqua. Quis ipsum
                                            suspendisse ultrices gravida. Risus
                                            commodo viverra maecenas accumsan
                                            lacus vel facilisis.
                                        </p>
                                        <div className="testimonial-user">
                                            <img
                                                src={require("../assets/images/avatar-small.png")}
                                                className="avatar-small circle"
                                                alt=""
                                            />
                                            <strong>Anna Gunn</strong>
                                        </div>
                                    </div>
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            /* <!-- Testimonial section end -->   */
        );
    }
}

export default Testimonial;
