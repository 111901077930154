import React, { Component } from "react";

class BusinessSetUp extends Component {
	constructor(props){
		super(props);
		this.state = {
			applications: [
				{
					url: '#/bussiness_setup/131',
					text: 'Tas heel',
					id: 131
				},
				{
					url: '#/bussiness_setup/141',
					text: 'P.R.O Services',
					id: 141
				},
				{
					url: '#/bussiness_setup/151',
					text: 'Documents clearing',
					id: 151
				},
				{
					url: '#/bussiness_setup/161',
					text: 'Tourist & Visa for UAE',
					id: 161
				},
				{
					url: '#/bussiness_setup/171',
					text: 'Visa for USA and Europe',
					id: 171
				},
				{
					url: '#/bussiness_setup/181',
					text: 'Translation Services',
					id: 181
				},
				{
					url: '#/bussiness_setup/191',
					text: 'Government Approvals',
					id: 191
				},
				{
					url: '#/bussiness_setup/101',
					text: 'Saudi Police Clarence Certificate',
					id: 101
				},
				{
					url: '#/bussiness_setup/112',
					text: 'UAE Police Clarence Certificate',
					id: 112
				},
				{
					url: '#/bussiness_setup/111',
					text: 'Labour & Imigration works',
					id: 111
				},
			
			]
		}
	}
	render(){
		const categories = this.state.applications.map(category => {
            return (
                <div className="col-md-4 col-sm-4" key={category.id}>
                    <a href={category.url} className="icon-list-block">
                        <span>{category.text}</span>
                    </a>
                </div>
            );
        });
		return(
			<section className="bg-light applications-padding">
                <div className="container">
                	<div className="row">
                        <div className="col-lg-7 col-md-9 m-auto text-center">
                            <div className="">
                                <span className="tagline">BUSINESS SETUP</span>
                            </div>
                        </div>
                    </div>
                	
                	<div className="row">
                	
                	{categories}
					
                  	</div>
                </div>
            </section>

		)
	}	
}

export default BusinessSetUp;