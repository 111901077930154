import React, { Component } from "react";
import Post from "./Post";
import axios from "../../axios-orders";

class Posts extends Component {
    state = {
        posts: null
    };

    componentDidMount() {
        axios.get("/posts.json").then(response => {
            const data = response.data.slice(0, this.props.postLimit);
            this.setState({
                posts: data
            });
        });
    }
    render() {
        let posts = "Loading...";
        if (this.state.posts) {
            posts = this.state.posts.map(post => {
                return (
                    <div
                        key={post.id}
                        className={
                            this.props.columnClass
                                ? this.props.columnClass
                                : "col-lg-4 col-md-6"
                        }
                    >
                        <Post {...post} />
                    </div>
                );
            });
        }

        return posts;
    }
}

export default Posts;
