import React, { Component } from "react";
import { Modal, Button, Tooltip } from 'antd';

class Categories extends Component {
    constructor(props){
        super(props);
        this.state = {
                categories: [
                    {
                        id: 0,
                        name: "Medical Oncology ",
                        image:"1.png",
                        "requirements":[
                        "MBBS or MBChB or equivalent qualification from an accredited institution and nd Master Degree in Medical Oncology.",
                        "Three (3) to five (5) years of experience in the same field"
                        ]
                    },
                    {
                        id: 1,
                        name: "Clinical Dietetics and Nutrition ",
                        image:"2.jpg",
                        "requirements": [
                        " Bachelor of Science in Dietetics or  Nutrition",
                        "Two (2) years’ experience post qualification in the related field"
                        ]
                    },
                    {
                        id: 2,
                        name: "Optometry ",
                        image:"3.jpg",
                        "requirements": [
                        "Bachelor degree or Diploma in Optometry/ Doctor of Optometry (Minimum three (3) years course duration).",
                        "Minimum two (2) years’ experience post qualification in the related field"
                        ]
                    },
                    {
                        id: 3,
                        name: "Renal Dialysis ",
                        image:"4.jpg",
                        "requirements": [
                        " Bachelor degree in Dialysis Technology or equivalent qualification in dialysis technology (Minimum three (3) years course duration).",
                        "Two (2) years’ experience post qualification in the related field"
                        ]
                    },
                    {
                        id: 4,
                        name: "Endoscopy ",
                        image:"5.jpg",
                        "requirements": [
                        "Bachelor degree from an accredited university in an Endoscopy.",
                        "Two (2) years’ experience post qualification in the Endoscopy field"
                        ]
                    },
                    {
                        id: 5,
                        name: "Radiographer ",
                        image:"6.jpg",
                        "requirements": [
                        "Bachelor degree from an accredited university in a Radiography, Radiology Technology or Medical/Diagnostic Imaging program.",
                        "Two (2) years’ experience post qualification in the general radiology field"
                        ]
                    },
                    {
                        id: 6,
                        name: "Ayurveda Practitioner ",
                        image:"7.jpg",
                        "requirements": [
                        "Professional Bachelor Degree in Indian medicine - Ayurveda (BAMS- Bachelor of Ayurvedic Medicine and Surgery) or the equivalent of minimum five (5) years full-time duration including internship (Comprising of a minimum of 4500 hours of classroom theory and practical sessions and 1000 hours of internship training).",
                        "Minimum of two (2) years’ experience after internship"
                        ]
                    },
                    {
                        id: 7,
                        name: "Homeopathy Practitioner ",
                        image:"8.jpg",
                        "requirements": [
                        " Professional Bachelor Degree in Homeopathy (BHMS- Bachelor in Homeopathic Medicine and Surgery) / licentiate from an accredited Homeopathic program of a minimum of five years including internship.",
                        "Minimum of two (2) years’ experience after internship"
                        ]
                    },
                    {
                        id: 8,
                        name: "Hijama Therapist ",
                        image:"9.png",
                        "requirements": [
                        "Holding a valid DHA/ Department of Health -Abu Dhabi /MOH healthcare professional license in any: medical/nursing/allied health specialities",
                        "Proof of completing a Hijama training program approved by MOH/DHA/ Department of Health -Abu Dhabi",
                        "Two (2) years’ experience post qualification in the related field"
                        ]
                    }
                ],
                visible: false,
                requirements: [],
                courseName: ""
        }
    }
    

    handleCancel = () =>{
        this.setState({visible: false})
    }
    handleOK = (category) =>{
        this.setState({
            requirements: [...category.requirements],
            visible: true,
            courseName: category.name
        })
    }
    render() {
        const text = <span>For more details click here.</span>;
        const categories = this.state.categories.map(category => {
            return (
                <Tooltip placement="top" title={text}>
                    <div className="col-md-4 col-sm-6" key={category.id} onClick={()=> this.handleOK(category)}>
                        <h6 className="icon-list-block" >{category.name}</h6>
                    </div>
                </Tooltip>
            );
        });

        return (
            <React.Fragment>
                <section className="categories bg-light">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 col-md-9 m-auto text-center">
                                <div className="sec-heading">
                                    <span className="tagline">Top categories</span>
                                    <h3 className="sec-title">
                                        Pick the right category Build your career
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="row">{categories}</div>
                    </div>
                </section>
                <Modal
                      title={"Requirements for " + this.state.courseName}
                      visible={this.state.visible}
                      onOk={this.handleCancel}
                      onCancel={this.handleCancel}
                      width={700}
                    >
                          { this.state.requirements.map( (category, i) => {
                                return <li key={i}>
                                <i className="ti-hand-point-right about-padding"></i>
                                    <span className="span-family">{category}</span>
                                </li>
                            })}
                </Modal>
            </React.Fragment>
        );
    }
}

export default Categories;
